import { axiosWebsiteInstance, axiosBackendInstance } from "../config/axios";


export const getWebsite = async ({ id }) => {
    try {
        const response = await axiosBackendInstance.get(`/api/websites/${id}`, {
            params: {
                populate: '*'
            }
        })
        return { success: true, data: response.data?.data }
    } catch (error) {
        console.error(error)
        return { success: false, data: {} }
    }
}

export const getWebsiteMetadata = async () => {
    try {
        const result = await axiosWebsiteInstance.get('/api/websites');
        return result?.data?.data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const addUserToNotification = async (data) => {
    try {
        const result = await axiosWebsiteInstance.post("/api/notification-users", {
            data: data
        })
        if (!result.status.toString().startsWith("2")) throw new Error()
        return true
    } catch (error) {
        console.log(error)
        return false
    }

}

export const getNotificationUsers = async () => {
    try {
        const result = await axiosWebsiteInstance.get("/api/notification-users")
        return result?.data?.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const createNotification = async (data) => {
    try {
        const result = await axiosWebsiteInstance.post("/api/notifications", {
            data: data
        })
        if (!result.status.toString().startsWith("2")) throw new Error()
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

export const getNotifications = async () => {
    try {
        const result = await axiosWebsiteInstance.get("/api/notifications?sort[0]=id:desc&pagination[page]=1&pagination[pageSize]=10&populate=*")
        return result?.data?.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const getNotificationsStats = async () => {
    try {
        const result = await axiosWebsiteInstance.get("/api/notification-stats?sort[0]=id:desc&populate=*")
        return result?.data?.data
    } catch (error) {
        console.log(error)
        return []
    }
}

export const updateNotification = async (id, data) => {
    try {
        const result = await axiosWebsiteInstance.put(`/api/notifications/${id}?populate=*`, {
            data: data
        })
        if (!result.status.toString().startsWith("2")) throw new Error()
        return { success: true, data: result.data.data }
    } catch (error) {

        return { success: true, error: error }
    }
}

export const updateRedirectUrl = async (id, data) => {
    try {
        const result = await axiosWebsiteInstance.put(`/api/websites/${id}`, {
            data: data
        })
        if (!result.status.toString().startsWith("2")) throw new Error()
        return { success: true, data: result.data }
    } catch (error) {
        return { success: false, error: error }
    }
}