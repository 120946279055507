import React, { useState, useEffect } from 'react'
import { requestForToken, isSupported } from '../../utils/firebase'
import { getWebsiteMetadata, addUserToNotification } from '../../services/websiteService';

export const NotificationContainer = ({websiteInfo }) => {

    const [isEnableNotificationActive, setIsEnableNotificationActive] = useState(false)

    const handleRequestToken = async () => {

        if (!isSupported()) {
            alert("browser does not support notification")
            return
        }
        const token = await requestForToken()
        console.log("token: ", token)
        if (token) {
            console.log(websiteInfo)
            const addResult = await addUserToNotification({
                url: websiteInfo.attributes?.url,
                notificationToken: token,
                isActive: true,
                website: { id: websiteInfo.id }
            })

            if (addResult) {
                window.localStorage.setItem('notificationToken', token)
                window.location.replace(websiteInfo.attributes.redirectUrl)
            }
        } else {
            console.log("token is null")
        }
    }

    useEffect(() => {
        if (isSupported()) {
            setIsEnableNotificationActive(websiteInfo?.attributes?.url && Notification.permission !== "granted" ? false : true)
        } else {
            setIsEnableNotificationActive(false)
        }
        console.log(websiteInfo)
        console.log(`notification token: ${localStorage.getItem('notificationToken')}`)
        if (isSupported() && websiteInfo.attributes) {
            if (Notification.permission === 'granted' && !localStorage.getItem('notificationToken')) {
                handleRequestToken()
            }
        }

    }, [websiteInfo])

    return (
        <div>
            <div className='flex justify-center'>
                <div>
                    <button disabled={isEnableNotificationActive} onClick={() => { handleRequestToken() }} type="button" className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Enable Notification</button>
                </div>
                <div>
                    <a href={websiteInfo?.attributes?.redirectUrl} type="button" className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Redirect Me!</a>
                </div>
            </div>
            <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left">
                If you enable notification, you will automaticly redirect next time
            </p>
        </div>
    )
}

export default NotificationContainer
