import { useState, useEffect } from 'react'
import { axiosWebsiteInstance, axiosBackendInstance } from '../config/axios'

import AdminNavBar from '../components/admin/AdminNavBar'
import UrlUpdateForm from '../components/admin/UrlUpdateForm'
import NotificationSendForm from '../components/admin/NotificationSendForm'
import NotificationTable from '../components/admin/NotificationTable'
import { useSelector, useDispatch } from 'react-redux'
import { getWebsite } from '../services/websiteService'
import { setWebsite } from '../store/websiteSlice'
const Admin = () => {
  const { user } = useSelector((state) => state.auth);
  const { website } = useSelector((state) => state.website);
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.website) {
      websiteRequest(user?.website?.id)
    }
  }, [user])

  const websiteRequest = async (id) => {
    const result = await getWebsite({ id })
    if (result.success) {
      dispatch(setWebsite(result.data))
    }
  }

  return (
    <section className="bg-white dark:bg-gray-900 h-auto">
      <AdminNavBar />
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
        <div className="grid md:grid-cols-2 gap-8 mb-8">

          <UrlUpdateForm />
          <NotificationSendForm />
          <div className='col-span-2 h-[415px] bg-gray-100 dark:bg-gray-800 dark:text-gray-100 text-gray-800 justify-center block overflow-y-scroll rounded-lg'>
            {website?.attributes?.att_notification &&
              <NotificationTable />
            }

          </div>
        </div>

      </div>
    </section>
  )
}

export default Admin