import { useState, useEffect } from 'react'
import TableLine from './Primitive/TableLine'
import { getNotificationUsers, getNotifications, getNotificationsStats } from '../../services/websiteService'

const NotificationTable = () => {

    const [activeNotificationUser, setActiveNotificationUser] = useState(0)
    const [notifications, setNotifications] = useState([])
    const [notificationsStats, setNotificationsStats] = useState([])

    useEffect(() => {
        handleUsersAmount()
        handleGetNotifications()
    }, [])

    const handleUsersAmount = async () => {
        const result = await getNotificationUsers()
        setActiveNotificationUser(result?.length)
    }

    const handleGetNotifications = async () => {
        const result = await getNotifications()
        
        setNotifications(result)
        const result2 = await getNotificationsStats()
        setNotificationsStats(result2)
    }

    useEffect(() => {

        if(notifications.length > 0){
            let notificationWithStats = notifications.map(notification => {
                const notificationStat = notificationsStats.find(notificationsStat => notificationsStat.attributes.notification.data.id === notification.id)
                notification.attributes.notificationsStat = notificationStat
                return notification
            })
            setNotifications(notificationWithStats)
        }

    }, [notificationsStats])

    return (
        <div className="relative overflow-x-auto shadow-md rounded-lg">
            <div className="flex items-center justify-between p-5 bg-gray-800">
                <div className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600  dark:focus:ring-gray-700" type="button">
                    <p>User Forcast: <span>{activeNotificationUser}</span></p>
                </div>
            </div>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        {/* <th scope="col" className="p-4">
                            ID
                            <div className="flex items-center">
                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                            </div>
                        </th> */}
                        <th scope="col" className="px-6 py-3">
                            Title
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Body
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Image
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Send
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Action
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Create Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Send Date
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Number of User
                        </th>
                    </tr>
                </thead>
                <tbody className='overflow-y-scroll	'>
                    {notifications?.map((notification, index) => {
                        return (
                            <TableLine key={index} notification={notification} />
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default NotificationTable