import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from '../services/authService'

const user = JSON.parse(localStorage.getItem("user"));
const token = JSON.parse(localStorage.getItem("token"));

const initialState = user
  ? { token, user }
  : { token: null, user: null };

export const login = createAsyncThunk(
  "auth/login",
  async ({ identifier, password }, thunkAPI) => {
    try {
      const data = await authService.login(identifier, password);
      const me = await authService.userInfo({ token: data.jwt })
      return { user: me, token: data.jwt };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      console.log(message)
      return thunkAPI.rejectWithValue('Credentials are wrong');
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    },
  },
});

const { reducer } = authSlice;

export default reducer;