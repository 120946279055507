import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    website: {}
}

const websiteSlice = createSlice({
    name: 'website',
    initialState,
    reducers: {
        setWebsite: (state, action) => {
            state.website = action.payload;
        },
        resetWebsite: (state) => {
            state.user = {};
        }
    },
    extraReducers: {},
});

export const { setWebsite, resetWebsite } = websiteSlice.actions;
const { reducer } = websiteSlice;
export default reducer;