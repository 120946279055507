import base from "axios";
import { store } from '../store/index'

export const axiosWebsiteInstance = base.create({
    headers: {
        'Authorization': `Bearer ${process.env.REACT_APP_WEBSITE_TOKEN}`
    },
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT
})

axiosWebsiteInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error)
        return error.response

    });

export const axiosInstance = base.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        "Access-Control-Allow-Credentials": true
    }
})

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error)
        return error.response

    });

export const axiosBackendInstance = base.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT
})

axiosBackendInstance.interceptors.request.use(
    config => {
        console.log(store.getState().auth.token)
        config.headers['Authorization'] = `Bearer ${store.getState().auth.token}`
        return config
    }
)
