import { useState } from 'react'
import { updateNotification } from '../../../services/websiteService'

const LINEHEIGHT = 72
const TRANSFORMHEIGHT = 120

const TableLine = ({ notification }) => {

    const [lineHeight, setLineHeight] = useState(LINEHEIGHT)
    const [isSend, setIsSend] = useState(notification?.attributes?.isSend)


    const handleSendNotification = async (id) => {
        
        const result = await updateNotification(id, {
            isSend: true
        })
        if(result.success){
            setIsSend(result?.data?.attributes?.isSend)
        }
    }

    return (
        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" style={{ height: lineHeight + "px" }}>
            <td className="flex items-center px-4 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                <div className="pl-3">
                    <div className="text-base font-semibold">{notification?.attributes?.title}</div>
                </div>
            </td>
            <td
                onClick={() => {
                    if (lineHeight === LINEHEIGHT) {
                        setLineHeight(TRANSFORMHEIGHT)
                    }else{
                        setLineHeight(LINEHEIGHT)
                    }
                }}
                className="px-4 py-4 max-w-[300px] cursor-pointer">
                <p className={`${LINEHEIGHT === lineHeight ? "line-clamp-1" : ""}`}>{notification?.attributes?.body}</p>
            </td>
            <td className="px-4 py-4">
                {notification?.attributes?.image &&
                    <img className="w-10 h-10 rounded-full" src={notification?.attributes?.image} alt="Jese" />
                }
            </td>
            <td className="px-4 py-4">

                {isSend ?
                    <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div> True
                    </div> :
                    <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div> False
                    </div>
                }
            </td>
            <td className="px-4 py-4">
                {!isSend &&
                    <button onClick={() => handleSendNotification(notification.id)} type="button" className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Send</button>
                }
            </td>
            <td className="px-4 py-4 max-w-[300px]">
                {notification?.attributes?.createdAt.split(".")[0].replace("T", " ")}
            </td>
            <td className="px-4 py-4 max-w-[300px]">
                {notification?.attributes?.createdAt === notification?.attributes?.updatedAt ?
                    <></> :
                    notification?.attributes?.updatedAt.split(".")[0].replace("T", " ")
                }
            </td>
            <td className="w-4 p-4">
                {notification?.attributes?.notificationsStat?.attributes?.successCount ? notification?.attributes?.notificationsStat?.attributes?.successCount : 0}
            </td>
        </tr>
    )
}

export default TableLine