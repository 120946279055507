import React from 'react'
import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PrivateRoute = ({ children }) => {
    const { token } = useSelector(state => state.auth)
    const location = useLocation();

    if (!token) {
        return <Navigate to="/" replace state={{ from: location }} />;
    }

    return children;
}

export default PrivateRoute