import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import websiteReducer from './websiteSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        website: websiteReducer
    },
});
