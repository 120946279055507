import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div>

            <h1>Privacy Policy for Redirect App</h1>

            <h2>Effective Date: [Insert Effective Date]</h2>

            <h3>1. Introduction</h3>

            <p>Welcome to Redirect App! This privacy policy is intended to inform you about the types of information we may collect, how we use it, and how you can exercise your privacy rights. Please read this policy carefully.</p>

            <h3>2. Information We Collect</h3>

            <h4>2.1 Personal Information:</h4>

            <p>We may collect the following types of personal information:</p>

            <ul>
                <li><strong>User-Provided Information:</strong> We may collect information you provide directly to us when you use our app. This may include, but is not limited to, your name, email address, and any other information you voluntarily provide.</li>

                <li><strong>Usage Information:</strong> We may collect information about your usage of the app, including, but not limited to, device information, log data, and analytics data. This information is collected to improve the performance and functionality of our app.</li>
            </ul>

            <h3>3. How We Use Your Information</h3>

            <p>We may use the information we collect for various purposes, including but not limited to:</p>

            <ul>
                <li>Providing and maintaining the app's functionality.</li>
                <li>Analyzing usage patterns to improve the app.</li>
                <li>Communicating with you about updates, promotions, and news related to the app.</li>
                <li>Responding to your inquiries or requests.</li>
                <li>Complying with legal and regulatory requirements.</li>
            </ul>

            <h3>4. Google Services</h3>

            <p>Our app may integrate with Google services, including but not limited to Google Analytics, Google AdMob, and Google Sign-In. By using our app, you agree to comply with Google's terms of service and privacy policies.</p>

            <h3>5. User Consent</h3>

            <p>By using our app, you consent to the collection and use of your information as described in this privacy policy.</p>

            <h3>6. Your Choices</h3>

            <p>You have the right to:</p>

            <ul>
                <li>Access, update, or delete your personal information.</li>
                <li>Opt out of receiving promotional emails.</li>
                <li>Disable certain data collection features within the app settings.</li>
            </ul>

            <p>To exercise any of these rights, please contact us at [Your Contact Information].</p>

            <h3>7. Data Security</h3>

            <p>We take data security seriously and implement reasonable security measures to protect your information. However, no method of transmission over the internet or electronic storage is entirely secure. Therefore, we cannot guarantee the absolute security of your data.</p>

            <h3>8. Changes to this Privacy Policy</h3>

            <p>We may update this privacy policy from time to time. We will notify you of any material changes by posting the updated policy on our website or within the app.</p>

            <h3>9. Contact Us</h3>

            <p>If you have any questions or concerns about this privacy policy, please contact us at <a href='mailto:redirectappio@gmail.com' >redirectappio@gmail.com</a>.</p>

        </div>
    )
}

export default PrivacyPolicy